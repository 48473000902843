<template>
  <div>
    <Menu />
    <div class="benefits">
      <div class="benefits__left">
        <div class="benefits__title">
          <h1 class="benefits__h1" itemProp="name">
            <!-- <i class="features__icon1 fal fa-exclamation"></i> -->
            <span style="color: #ff9216">¡Corte por facturación!</span>
            <!-- <i class="features__icon fal fa-exclamation"></i> -->
          </h1>
        </div>

        <p class="benefits__text" itemProp="description">
          Oooops! No pudimos encontrar los ultimos pagos de tu factura, para
          reestablecer el servicio realiza el pago del mismo y envianos el
          soporte.
        </p>
      </div>
      <div class="benefits__right">
        <img
          class="benefits__img img-fluid"
          itemProp="image"
          src="../assets/img/pendiente-facturacion.svg"
          alt="Imagen de interface Checklist digitales."
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";

export default {
  name: "Beneficios",
  components: {
    Menu,
    Footer,
  },
  metaInfo() {
    return {
      title: "Qinspecting |",
      titleTemplate: "%s Corte por facturación.",
      meta: [
        {
          name: "robots",
          content: "noindex",
        },
        {
          description: "Qinspecting | Corte por facturación",
        },
        {
          keywords:
            "Qinspecting, beneficios qinspecting, afiliarme a yanbal, Incorporación a yanbal, yanbal Bogotá, yanbal Colombia, Revista yanbal, Catalogo yanbal, Ser Directora Yanbal, Ser Consultora Yanbal, Ser Vendedora Yanbal",
        },
        { author: "Appears" },
      ],
    };
  },
};
</script>

<style scoped>
.features__icon {
  font-size: 80px;
  font-weight: 300;
  color: var(--secondary);
  /* width: 80px; */
  margin-left: 20px;
}
.features__icon1 {
  font-size: 80px;
  font-weight: 300;
  color: var(--secondary);
  width: 50px;
  transform: rotate(180deg);
  margin-right: 20px;
}

.benefits {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  padding: 100px 0;
  background: var(--white);
  border-top: 0.5px solid #e4e4e4;
}
@media screen and (max-width: 991px) {
  .benefits {
    grid-template-columns: 100%;
    padding: 50px 0 40px 0;
  }
}

.benefits__left {
  position: relative;
  z-index: 1;
  padding-left: 15%;
}
@media screen and (max-width: 991px) {
  .benefits__left {
    margin-bottom: 20px;
    padding: 0 7%;
  }
}

.benefits__title {
  display: flex;
  align-items: center;
}

.benefits__h1 {
  display: inline-block;
  font-size: 100px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--primary);
}
@media screen and (max-width: 1199px) {
  .benefits__h1 {
    font-size: 55px;
  }
}

@media screen and (max-width: 767px) {
  .benefits__h1 {
    font-size: 50px;
  }
}

@media screen and (max-width: 480px) {
  .benefits__h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 400px) {
  .benefits__h1 {
    font-size: 35px;
  }
}

@media screen and (max-width: 240px) {
  .benefits__h1 {
    font-size: 30px;
  }
}

.benefits__text {
  font-size: 30px;
  margin-bottom: 0;
  font-weight: 500;
  color: #131313;
}
@media screen and (max-width: 480px) {
  .benefits__text {
    font-size: 17px;
  }
}

@media screen and (max-width: 240px) {
  .benefits__text {
    font-size: 14px;
  }
}

.benefits__right {
  justify-self: center;
  padding-right: 15%;
}
@media screen and (max-width: 991px) {
  .benefits__right {
    padding: 0 7%;
  }
}

.benefits__img {
  height: 500px;
  /* max-width: 450px; */
}
@media screen and (max-width: 1199px) {
  .benefits__img {
    max-width: 350px;
  }
}
@media screen and (max-width: 991px) {
  .benefits__img {
    max-width: 100%;
  }
}
</style>
